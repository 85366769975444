
















































@import "src/assets/style/global"
.name
  margin-top: 30px
  font-size: 2.5rem
  color: white
  font-style: italic
.something
  max-width: 1000px
  margin: 150px auto 0 auto
  .clause
    margin-top: 20px
    color: white
    font-size: 1rem
    font-style: italic
